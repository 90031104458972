import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type TotalGrnDataHash = {
  received_at: string;
  quantity: number;
}

export type TotalStockSalesReportDataHash = {
  unique_key : number,
  sku: string,
  price: number,
  fbv_enabled: boolean,
  title: string,
  category: string,
  product_image: string | null,
  total_grn_data: TotalGrnDataHash[],
  total_received_quantity: number,
  total_rto_quantity: number,
  total_return_quantity: number,
  total_order_quantity: number,
  sold_quantity: number,
  return_rto_quantity: number,
  sales_amount: number,
  current_stock_quantity: number
}

type TotalStockSalesReportData = {
  total_stock_sales_data: TotalStockSalesReportDataHash[];
  available_categories: string[]
}

type TotalStockSalesReportApiState = {
  totalStockSalesReportData?: TotalStockSalesReportData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof TotalStockSalesReportDataHash | null;
  totalStockSalesReportStatus: "idle" | "loading" | "failed";
  totalStockSalesReportError: string | null;
};

const initialState: TotalStockSalesReportApiState = {
  totalStockSalesReportData: null,
  totalStockSalesReportStatus: "loading",
  totalStockSalesReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};


const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/stocks/total_stock_sales_report${download ? '.csv' : ''}?`;
  url += `category=${requestData.stockSalesReportCategoryFilter}&`;
  url += `product_id=${requestData.stockSalesReportProductIdFilter}`;
  return url;
}

export const totalStockSalesReportApi = createAsyncThunk(
  "totalStockSalesReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const totalStockSalesReportDownloadReportApi = createAsyncThunk(
  "totalStockSalesReportDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData, true);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const totalStockSalesReportSlice = createSlice({
  name: 'totalStockSalesReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof TotalStockSalesReportDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.totalStockSalesReportData && column){
        const sorted = sortRecords(state.totalStockSalesReportData.total_stock_sales_data, column, direction);
        state.totalStockSalesReportData.total_stock_sales_data = sorted as TotalStockSalesReportDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(totalStockSalesReportApi.pending, (state) => {
        state.totalStockSalesReportStatus = "loading";
        state.totalStockSalesReportError = null;
      })
      .addCase(
        totalStockSalesReportApi.fulfilled,
        (state, action: PayloadAction<TotalStockSalesReportData>) => {
          state.totalStockSalesReportStatus = "idle";
          state.totalStockSalesReportData = action.payload;
        }
      )
      .addCase(totalStockSalesReportApi.rejected, (state, action) => {
        state.totalStockSalesReportStatus = "failed";
        if (action.payload) {
          state.totalStockSalesReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.totalStockSalesReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = totalStockSalesReportSlice.actions;
export default totalStockSalesReportSlice.reducer;
