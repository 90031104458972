import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../store";
import styles from "./PenaltyReports.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { resetAuth } from "../../slices/authSlice";
import Loader from "components/atoms/Loader/Loader";
import {
  fetchOosPenalties,
  ProductVariant,
} from "../../slices/oosPenaltySlice";
import { fixedColumn, oosPenaltyColumns } from "./OosPenalty.constant";
import { Box, Tab, Tabs, Button, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/organisms/TableManager/TableManager";
import DateRangeSelector, {
  DateRangeLabel,
} from "components/molecules/DateRangeSelector/DateRangeSelector";
import { resetFilter, setOosPenaltyReportMonthFilter as setSelectedMonths } from "../../slices/filtersSlice";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import {
  MIXPANEL_EVENT_TYPES,
  triggerMixpanelEvent,
} from "../../hooks/mixpanel_hook";
import { DEFAULT_BLACK_COLOR, ROUTE_REPORT_MAP, ROUTES } from "../../constants";
import MonthSelector, {
  SelectedMonth,
} from "components/molecules/MonthSelector/MonthSelector";
const OosPenalty: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Constants
  const PAGE_TITLE = "OOS Penalty Report";

  // Redux state
  const { product_variants, summary } = useAppSelector(
    (state: RootState) => state.oosPenalty
  );

  const [trackingProgress, setTrackingProgress] = useState<
    { label: string; value: string }[]
  >([]);
  const [showProgressDrawer, setShowProgressDrawer] = useState(false);

  const showTrackingProgress = (steps: { label: string; value: string }[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  };

  // Local state
  const [loaderActive, setLoaderActive] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [showMonthOptions, setShowMonthOptions] = useState<boolean>(false);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  // Handle month selection
  const currentDate = new Date();

  const selectedMonths = useAppSelector((root: RootState) => root.filters.oosPenaltyReportMonthFilter);

  const handleMonthSelection = (month: number, year: number) => {
    setSelectedMonths([{ month, year }]); // Replace any existing selection
    setFilterApplied(true); // Trigger fetch on selection
  };

  const handleDisabledMonths = (month: number, year: number): boolean => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    if ((year > currentYear || year < 2024) || (year === currentYear && month >= currentMonth)) {
      return true;
    }
    return false;
  };

  const handleClear = () => {
    const currentDate = new Date();
    setSelectedMonths([
      {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
    ]);
    setFilterApplied(true); 
  };

  // Fetch data
  const fetchData = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      dispatch(resetAuth());
      dispatch(resetFilter());
      return;
    }

    const token = JSON.parse(userInfo).token;
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const result = await dispatch(
        fetchOosPenalties({ month: selectedMonths[0].month, year: selectedMonths[0].year, headers })
      ).unwrap();
      console.log("Fetched OOS penalty data:", result);
    } catch (error) {
      console.error("Error fetching OOS penalties:", error);
    } finally {
      setFilterApplied(false);
      setLoaderActive(false);
    }
  };

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [filterApplied]);

  // Tab Change Handler
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.REPORT_VIEWED, {
      report_name: ROUTE_REPORT_MAP[newValue],
      source: "Navigation",
    });
    navigate(newValue);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <MonthSelector
          onClick={handleMonthSelection}
          showCalendar={showMonthOptions}
          setShowCalendar={setShowMonthOptions}
          disabledMonths={handleDisabledMonths}
          selectedMonths={selectedMonths}
          onClear={handleClear}
          onSubmit={() => {
            setFilterApplied(true); // Trigger data fetch for selected month
            setShowMonthOptions(false); // Close the month selector
          }}
        >
          <DateRangeLabel
            noDateLabel={'Jan 20234'}
            onClick={() => {
              setShowMonthOptions(true);
            }}
          />
        </MonthSelector>
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={handleTabChange}
          TabIndicatorProps={{ sx: { backgroundColor: "#4F76FE" } }}
        >
          {[
            { label: "Out of Stock", route: ROUTES.OOS_PENALTY },
            { label: "Returns", route: ROUTES.RETURN_PENALTY },
          ].map(({ label, route }) => (
            <Tab
              key={route}
              label={label}
              value={route}
              style={{
                textTransform: "none",
                fontWeight: 400,
                color:
                  window.location.pathname === route
                    ? "#3361FF"
                    : DEFAULT_BLACK_COLOR,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {!loaderActive && (
      <Box className={styles.paymentSummaryWrapper}>
        <DataRow className={styles['payment-summary']}
            separator>
          {[
            { label: "SKUs Live", value: summary?.total_products || 0 },
            {
              label: "Out of Stock days",
              value: `${summary?.oos_percentage || 0}%`,
            },
            {
              label: "Total Stock time (SKU X 1 month)",
              value: summary?.total_stock_days_last_month || 0,
            },
            {
              label: "Penalty Amount",
              value: summary?.penalty?.penalty_amount || 0,
            },
          ].map(({ label, value }, index) => (
            <DataCol
              key={index}
              colClass={styles['individual-payment']}
              labelClass={styles["payment-cycle"]}
              valueClass={styles['payment-value']}
              label={label}
              value={value}
            />
          ))}
        </DataRow>
      </Box>
)}
      <Loader show={loaderActive} />

      {!loaderActive && (
          <TableManager<ProductVariant>
            columns={oosPenaltyColumns({ showTrackingProgress, isMobile })}
            data={product_variants}
            showPagination
            totalCount={product_variants.length || 0}
            sortedColumn={null}
            handleSort={() => {}}
            sortDirection={"desc"}
            currentPage={page}
            rowPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            fixedColumn={isMobile ? fixedColumn : undefined}
          />
      )}
    </Box>
  );
};

export default OosPenalty;
