import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { csx } from "../../helpers/utils";
import { lakhFormat } from "helpers/formatUtils";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataRow, DataCol } from "components/atoms/ChartData/ChartData";
import { RootState } from "store";
import { resetFilter, setEndDateFilter, setSalesReportOrderCreatedEndDateFilter, setSalesReportOrderCreatedStartDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { Link } from "react-router-dom";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { ROUTE_REPORT_MAP } from "../../constants";

const triggerReportViewedEvent = (path: string) => {
  triggerMixpanelEvent(
    MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
    {
      report_name: ROUTE_REPORT_MAP[path],
      source: 'Dashboard'
    }
  );
}

const SkuInfoChart = () => {

  const dashboardData = useAppSelector((state) => state.dashboard);

  return (
    <DataRow
      separator={true}
      children={[
        <DataCol
          label={'Unique products live & published'} 
          value={dashboardData.productSales?.unique_products_live_count} 
        />,
        <DataCol 
          label={'Unique products live & instock'} 
          value={
            <>
              {dashboardData.productSales?.unique_products_live_instock_count} <span className={csx(styles['data-value-brackets'], styles['gray-color'])}> {dashboardData.productSales?.unique_products_live_instock_count_percent}</span>
            </>
          }
        />,
      ]}
    />
  );

}


const SalesInfoChart = () => {

  const dashboardData = useAppSelector((state) => state.dashboard);

  const dispatch = useAppDispatch();

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  return (
    <div className={styles['data-link-wrapper']}>
      <Link className={styles["chart-navigation"]} to={'/top-selling-product-report'}
        onClick={() => {
          dispatch(resetFilter());
          dispatch(setSalesReportOrderCreatedStartDateFilter(startDateFilter));
          dispatch(setSalesReportOrderCreatedEndDateFilter(endDateFilter));
          dispatch(setStartDateFilter(startDateFilter));
          dispatch(setEndDateFilter(endDateFilter));
          triggerReportViewedEvent('/sales-report')
        }}
      >
        {`View`} <ChevronRightOutlinedIcon fontSize="small"/>
      </Link>
      <DataRow
        separator={true}
        children={[
          <DataCol
            label={'Unique products sold in selected date range'}
            value={
              <>
                {dashboardData.productSales?.unique_products_sold_count} <span className={csx(styles['data-value-brackets'], styles['gray-color'])}>{dashboardData.productSales?.unique_products_sold_count_percent}</span>
              </>
            }
          />,
          <DataCol
            label={'Total quantity sold in selected date range'}
            value={dashboardData.productSales?.total_quantity_sold_count}
          />,
          <DataCol
            label={'Total value sold in selected date range'}
            value={lakhFormat(dashboardData.productSales?.total_value_sold_count || 0)}
          />,
          <DataCol
            label={'Average selling price'}
            value={lakhFormat(dashboardData.productSales?.average_selling_price || 0)}
          />
        ]}
      />
    </div>
  );
}

const CompleteInfoChart = () => {

  const dispatch = useAppDispatch();

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  const dashboardData = useAppSelector((state) => state.dashboard);

  return (
    <>
      <ChartHeader
        headerTxt={'Product Sales Summary'}
        tooltipTxt={'Concise overview or report that outlines the sales performance of various products within a specific period.'}
        link={'/top-selling-product-report'}
        onClick={() => {
          dispatch(resetFilter());
          dispatch(setSalesReportOrderCreatedStartDateFilter(startDateFilter));
          dispatch(setSalesReportOrderCreatedEndDateFilter(endDateFilter));
          dispatch(setStartDateFilter(startDateFilter));
          dispatch(setEndDateFilter(endDateFilter));
          triggerReportViewedEvent('/sales-report');
        }}
      />
      <DataRow
        className={styles['data-row']}
        children={[
          <DataCol
            label={'Unique products live & published'} 
            value={dashboardData.productSales?.unique_products_live_count} 
          />,
          <DataCol
            label={'Unique products live & instock'} 
            value={
              <>
                {dashboardData.productSales?.unique_products_live_instock_count} <span className={csx(styles['data-value-brackets'], styles['gray-color'])}> {dashboardData.productSales?.unique_products_live_instock_count_percent}</span>
              </>
            }
          />,
        ]}
      />
      <DataRow
        className={styles['data-row']}
        children={[
          <DataCol
            label={'Unique products sold in selected date range'}
            value={
              <>
                {dashboardData.productSales?.unique_products_sold_count} <span className={csx(styles['data-value-brackets'], styles['gray-color'])}>{dashboardData.productSales?.unique_products_sold_count_percent}</span>
              </>
            }
          />,
          <DataCol
            label={'Total quantity sold in selected date range'}
            value={dashboardData.productSales?.total_quantity_sold_count}
          />,
        ]}
      />
      <DataRow
        className={styles['data-row']}
        children={[
          <DataCol
            label={'Total value sold in selected date range'}
            value={lakhFormat(dashboardData.productSales?.total_value_sold_count || 0)}
          />,
          <DataCol
            label={'Average selling price'}
            value={lakhFormat(dashboardData.productSales?.average_selling_price || 0)}
          />
        ]}
      />
    </>
  );
}


type PropType  = {
  variant?: "sku-info" | "sales-info" | "complete-info"
}

const ProductSalesChart: React.FC<PropType> = ({variant = "completeInfo"}) => {

  const dashboardData = useAppSelector((state) => state.dashboard);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const chartName = "product-sales-" + variant + '-chart';

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('sales_summary')}>
      {dashboardData.productSalesStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.productSalesStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.productSalesError}</p>}
      {dashboardData.productSalesStatus === 'idle' && variant === 'sku-info' && (
        <SkuInfoChart />
      )}
      {dashboardData.productSalesStatus === 'idle' && variant === 'sales-info' && (
        <SalesInfoChart />
      )}
      {dashboardData.productSalesStatus === 'idle' && variant === 'complete-info' && (
        <CompleteInfoChart />
      )}
    </div>
  )
}

export default ProductSalesChart;