import { ProductVariant} from "../../slices/oosPenaltySlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from './PenaltyReports.module.scss';
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";


type OosReportColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: {label: string, value: string}[]) => void;
}

export const fixedColumn:FixedColumnPropType<ProductVariant> = {
  label: 'Product / SKU',
  accessor: 'sku',
  width: 120,
  maxWidth: 120,
  thresold: (290 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.sku || ''}
    src={row.image_src || ''}
    fbv_enabled={row.fbv_enabled}
  />
}

export const oosPenaltyColumns = (props: OosReportColumnsPropType):ColumnPropType<ProductVariant>[] => [
  {
    label: 'Product / SKU',
    accessor: 'sku',
    minWidth: 480,
    width: 290,
    cell: ({row}) => <ProductThumbnail 
      sku={row.sku || ''}
      title={row.title}
      src={row.image_src  || ''}
      fbv_enabled={row.fbv_enabled}
  
    />
  },
  {
    label: 'Number of OOS days',
    accessor: 'oos_days',
    minWidth: 390,
  },
  {
    label: 'Number of OOS days in Last month',
    accessor: 'oos_days_last_month',
    minWidth: 390,
  },
  
 
];