import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../store";
import styles from "./PenaltyReports.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { resetAuth } from "../../slices/authSlice";
import Loader from "components/atoms/Loader/Loader";
import {
  MIXPANEL_EVENT_TYPES,
  triggerMixpanelEvent,
} from "../../hooks/mixpanel_hook";
import {
  DateRangeType,
  DEFAULT_BLACK_COLOR,
  ROUTE_REPORT_MAP,
  ROUTES,
} from "../../constants";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, returnsPenaltyColumns } from "./ReturnPenalty.constant";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import {
  setReturnsPenaltyReceivedAtStartDateFilter,
  setReturnsPenaltyReceivedAtEndDateFilter,
  setReturnsPenaltyReturnedAtStartDateFilter,
  setReturnsPenaltyReturnedAtEndDateFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import DateRangeSelector, {
  DateRangeLabel,
} from "components/molecules/DateRangeSelector/DateRangeSelector";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchReturnPenalties,
  ReturnData,
  BreakdownReason,
} from "slices/returnsPenaltySlice";

const ReturnPenalty: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const PAGE_TITLE = "Return Penalty Report";

  // Redux state
  const { returns_data, pagination, summary } = useAppSelector(
    (state: RootState) => state.returnPenalty
  );
  const {
    returnsPenaltyReturnedAtStartDateFilter,
    returnsPenaltyReturnedAtEndDateFilter,
    returnsPenaltyReceivedAtStartDateFilter,
    returnsPenaltyReceivedAtEndDateFilter,
  } = useAppSelector((state: RootState) => state.filters);

  // Local states
  const [loaderActive, setLoaderActive] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [showReturnDate, setShowReturnDate] = useState(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [trackingProgress, setTrackingProgress] = useState<{ label: string; value: string }[]>([]);
  const [showProgressDrawer, setShowProgressDrawer] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showRaisedDate, setShowRaisedDate] = useState<boolean>(true);

  // Handlers
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setFilterApplied(true);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setFilterApplied(true);
  };

  const handleSort = useCallback((column: keyof ReturnData) => {
    console.log("Sorting by column:", column);
  }, []);

  const handleStartEndDateSelect = useCallback((ranges: DateRangeType) => {
    const { startDate, endDate } = ranges;
    if (showReturnDate) {
      dispatch(setReturnsPenaltyReturnedAtStartDateFilter(startDate as Date));
      dispatch(setReturnsPenaltyReturnedAtEndDateFilter(endDate as Date));
    } else {
      dispatch(setReturnsPenaltyReceivedAtStartDateFilter(startDate as Date));
      dispatch(setReturnsPenaltyReceivedAtEndDateFilter(endDate as Date));
    }
    setFilterApplied(true);
  }, [showReturnDate, dispatch]);

  const showTrackingProgress = useCallback((steps: { label: string; value: string }[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  }, []);

  const fetchData = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      dispatch(resetAuth());
      dispatch(resetFilter());
      return;
    }

    const token = JSON.parse(userInfo).token;
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const result = await dispatch(
        fetchReturnPenalties({
          startDateFilter: returnsPenaltyReturnedAtStartDateFilter,
          endDateFilter: returnsPenaltyReturnedAtEndDateFilter,
          returnsPenaltyReceivedAtStartDateFilter,
          returnsPenaltyReceivedAtEndDateFilter,
          page,
          rowsPerPage,
          headers,
        })
      ).unwrap();
      console.log("Fetched data successfully:", result);
    } catch (error) {
      console.error("Error fetching return penalties:", error);
    } finally {
      setFilterApplied(false);
      setLoaderActive(false);
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    returnsPenaltyReturnedAtStartDateFilter,
    returnsPenaltyReturnedAtEndDateFilter,
    returnsPenaltyReceivedAtStartDateFilter,
    returnsPenaltyReceivedAtEndDateFilter,
  ]);

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [filterApplied, fetchData]);

  const handleTabChange = useCallback((newValue: string) => {
    triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.REPORT_VIEWED, {
      report_name: ROUTE_REPORT_MAP[newValue],
      source: "Navigation",
    });
    navigate(newValue);
  }, [navigate]);

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <DateRangeLabel
          startDate={showReturnDate ? returnsPenaltyReturnedAtStartDateFilter : returnsPenaltyReceivedAtStartDateFilter}
          endDate={showReturnDate ? returnsPenaltyReturnedAtEndDateFilter : returnsPenaltyReceivedAtEndDateFilter}
          onClick={() => setShowDateRangeFilter(true)}
        />
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={(event, newValue) => handleTabChange(newValue)}
          TabIndicatorProps={{ sx: { backgroundColor: "#4F76FE" } }}
        >
          <Tab
            label="Out of Stock"
            value={ROUTES.OOS_PENALTY}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color: window.location.pathname === ROUTES.OOS_PENALTY ? "#3361FF" : DEFAULT_BLACK_COLOR,
            }}
          />
          <Tab
            label="Returns"
            value={ROUTES.RETURN_PENALTY}
            style={{
              textTransform: "none",
              fontWeight: 400,
              color: window.location.pathname === ROUTES.RETURN_PENALTY ? "#3361FF" : DEFAULT_BLACK_COLOR,
            }}
          />
        </Tabs>
      </Box>
      {!loaderActive && (
      <Box className={styles.paymentSummaryWrapper}>
        <DataRow
          className={styles["payment-summary"]}
          separator
          children={[
            ...(summary?.breakdown_by_reason?.map((item: BreakdownReason, index: number) => (
              <DataCol
                key={`reason-${index}`}
                colClass={styles["individual-payment"]}
                labelClass={styles["payment-cycle"]}
                valueClass={styles["payment-value"]}
                label={item.reason || "N/A"}
                value={item.count || 0}
              />
            )) || []),
            <DataCol
              key="total-penalty"
              colClass={styles["individual-payment"]}
              labelClass={styles["payment-cycle"]}
              valueClass={styles["payment-value"]}
              label="Total Penalty Amount"
              value={summary?.total_penalty_amount || 0}
            />,
          ]}
        />
      </Box>
      )}
      <Loader show={loaderActive} />

      {!loaderActive && (
          <TableManager<ReturnData>
            columns={returnsPenaltyColumns({ showTrackingProgress, isMobile })}
            data={returns_data}
            currentPage={page}
            rowPerPage={rowsPerPage}
            totalCount={pagination?.total_count || 0}
            sortedColumn={null}
            handleSort={handleSort}
            onRowPerPageChange={handleChangeRowsPerPage}
            sortDirection="desc"
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 25, 100]}
            isDataPaginated
            fixedColumn={isMobile ? fixedColumn : undefined}
          />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => setShowDateRangeFilter(false)}
        startDate={showReturnDate ? returnsPenaltyReturnedAtStartDateFilter : returnsPenaltyReceivedAtStartDateFilter}
        endDate={showReturnDate ? returnsPenaltyReturnedAtEndDateFilter : returnsPenaltyReceivedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        onOpen={() => setShowDateRangeFilter(true)}
      />
    </Box>
  );
};

export default ReturnPenalty;
