import Box from '@mui/material/Box';
import { Collapse, List, ListItem, ListItemText} from '@mui/material';
import { Link } from 'react-router-dom';
import './SideBar.scss';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {ROUTE_REPORT_MAP, ROUTES} from "../../../constants";
import { useLocation } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useDispatch } from 'react-redux';
import { resetAuth } from 'slices/authSlice';
import fbvIcon from "images/fbv-icon.png";
import fbvIconWhite from "images/fbv-icon-white.png";
import { resetFilter } from 'slices/filtersSlice';
import { useAppSelector } from 'hooks/redux-hooks';
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from 'hooks/mixpanel_hook';

const SideBar: React.FC = () => {

  const location = useLocation();

  const isSelected = (route: string) => {
    if(route === ROUTES.ISSUE_TRACKING && location.pathname.startsWith(ROUTES.ISSUE_TRACKING))
      return 'selected';
    return location.pathname === route ? 'selected' : '';
  }

  const [expandReportTab, setExpandReportTab] = useState(false);

  const dispatch = useDispatch();
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetFilter());
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Navigation'
      }
    );
  }

  return (
    <Box  className="nav-sidebar-wrapper">
      <List className="sidebar-link-list">
        <ListItem className={`sidebar-link ${isSelected(ROUTES.DASHBOARD)}`} component={Link} to={ROUTES.DASHBOARD} >
          <ListItemText primary="Dashboard" className='link-text' />
        </ListItem>
        <ListItem className={`sidebar-link ${isSelected(ROUTES.ORDER_TRACKING)}`} component={Link} to={ROUTES.ORDER_TRACKING} onClick={() => triggerReportViewedEvent(ROUTES.ORDER_TRACKING)}>
          <ListItemText primary="Order Tracking" className='link-text' />
        </ListItem>
        <ListItem className={`sidebar-link ${isSelected(ROUTES.PRODUCT_CATALOGUE)}`} component={Link} to={ROUTES.PRODUCT_CATALOGUE} onClick={() => triggerReportViewedEvent(ROUTES.PRODUCT_CATALOGUE)}>
          <ListItemText primary="Products" className='link-text' />
        </ListItem>
        <ListItem className="sidebar-link collapse-trigger" onClick={() => setExpandReportTab(prevVal => !prevVal)}>
          <ListItemText primary="Reports" className='link-text' />
          {expandReportTab ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandReportTab} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={`collapse-sidebar-link-list`}>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.SOH_REPORT)}`} component={Link} to={ROUTES.SOH_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.SOH_REPORT)}>
              <ListItemText primary="SOH Report" className='link-text sub-link' />
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.TOP_SELLING_PRODUCTS_REPORT)}`} component={Link} to={ROUTES.TOP_SELLING_PRODUCTS_REPORT} >
              <ListItemText primary="Top selling product" className='link-text sub-link' />
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.TOTAL_STOCK_AND_SALES_REPORT)}`} component={Link} to={ROUTES.TOTAL_STOCK_AND_SALES_REPORT} >
              <ListItemText className='link-text sub-link'>
                Stock and Sales Report <img className={`fbv-icon`} src={isSelected(ROUTES.TOTAL_STOCK_AND_SALES_REPORT) ? fbvIconWhite : fbvIcon} />
              </ListItemText>
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.LOR_REPORT)}`} component={Link} to={ROUTES.LOR_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.LOR_REPORT)}>
              <ListItemText primary="LOR Report" className='link-text sub-link' />
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.RETURNS)} ${isSelected(ROUTES.EXCHANGES)}` } component={Link} to={ROUTES.RETURNS} onClick={() => triggerReportViewedEvent(ROUTES.RETURNS)}>
              <ListItemText primary="Returns & Exchange Report" className='link-text sub-link' />
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.RTO)}`} component={Link} to={ROUTES.RTO} onClick={() => triggerReportViewedEvent(ROUTES.RTO)}>
              <ListItemText primary="RTO Report" className='link-text sub-link' />
            </ListItem>
            <ListItem className={`sidebar-link ${isSelected(ROUTES.GSTR_REPORT)}`} component={Link} to={ROUTES.GSTR_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.GSTR_REPORT)}>
              <ListItemText primary="GSTR Report" className='link-text sub-link'/>
            </ListItem>
            {fbv && (
              <ListItem className={`sidebar-link ${isSelected(ROUTES.FBV_STORAGE_CHARGES_REPORT)}`} component={Link} to={ROUTES.FBV_STORAGE_CHARGES_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.FBV_STORAGE_CHARGES_REPORT)}>
                <ListItemText primary="FBV Storage Charges" className='link-text sub-link'/>
              </ListItem>
            )}
          </List>
        </Collapse>
        <ListItem className={`sidebar-link ${isSelected(ROUTES.PAYMENT)}`} component={Link} to={ROUTES.PAYMENT} onClick={() => triggerReportViewedEvent(ROUTES.PAYMENT)}>
          <ListItemText primary="Payments" className='link-text' />
        </ListItem>
        <ListItem className={`sidebar-link ${isSelected(ROUTES.OOS_PENALTY)} ${isSelected(ROUTES.RETURN_PENALTY)}` } component={Link} to={ROUTES.OOS_PENALTY} onClick={() => triggerReportViewedEvent(ROUTES.OOS_PENALTY)}>
          <ListItemText primary="Penalty Reports" className='link-text' />
        </ListItem>
        <div className={`bottom-btn-wrapper`}>
          <ListItem className={`sidebar-link ${isSelected(ROUTES.MRP_LABEL)}`} component={Link} to={ROUTES.MRP_LABEL} onClick={() => triggerReportViewedEvent(ROUTES.MRP_LABEL)}>
            <ListItemText primary="MRP Label" className='link-text' />
            <DescriptionOutlinedIcon fontSize='small' />
          </ListItem>
          <ListItem className={`sidebar-link ${isSelected(ROUTES.ISSUE_TRACKING)}`} component={Link} to={ROUTES.ISSUE_TRACKING} onClick={() => triggerReportViewedEvent(ROUTES.ISSUE_TRACKING)}>
            <ListItemText primary="Help Center" className='link-text' />
            <HelpOutlineIcon fontSize='small' />
          </ListItem>
        </div> 
      </List>
    </Box>
  );
}

export default SideBar;