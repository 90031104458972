import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import styles from "./ReportList.module.scss";
import { Link } from "react-router-dom";
import {ROUTE_REPORT_MAP, ROUTES} from "../../constants";
import soh_icon from "images/soh_icon.png"
import sales_icon from "images/sales_icon.png"
import lor_icon from "images/lor_icon.png"
import gstr_icon from "images/gstr_icon.png"
import rto_icon from "images/rto_icon.png"
import return_icon from "images/return_icon.png";
import stock_sales_icon from "images/stock-sales-icon.png";
import storage_icon from "images/storage_icon.png"
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useAppSelector } from "hooks/redux-hooks";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "hooks/mixpanel_hook";

const Header: React.FC = () => {
  return (
    <Box className={styles.headerWrapper}>
      <Typography className={styles.label}>{'Reports'}</Typography>
    </Box>
  );
}

const ReportList = () => {

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Navigation'
      }
    );
  }

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  return (
    <Box className={styles.layoutWrapper}>
      <Header />
      <List component="div" disablePadding className={styles.linkList}>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.SOH_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.SOH_REPORT)}>
          <img src={soh_icon} alt={'SOH Report Link'} />
          <ListItemText primary="SOH Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.TOP_SELLING_PRODUCTS_REPORT} >
          <img src={sales_icon} alt={'Top Selling Product Report Link'} />
          <ListItemText primary="Top selling product" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.TOTAL_STOCK_AND_SALES_REPORT} >
          <img src={stock_sales_icon} alt={'Stock and Sales Report Link'} />
          <ListItemText primary="Stock and Sales Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.LOR_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.LOR_REPORT)}>
          <img src={lor_icon} alt={'LOR Report Link'} />
          <ListItemText primary="LOR Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.RETURNS} onClick={() => triggerReportViewedEvent(ROUTES.RETURNS)}>
          <img src={return_icon} alt={'Returns Report Link'} />
          <ListItemText primary="Returns & Exchange Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.RTO} onClick={() => triggerReportViewedEvent(ROUTES.RTO)}>
          <img src={rto_icon} alt={'RTO Report Link'} />
          <ListItemText primary="RTO Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        <ListItem className={styles.linkItem} component={Link} to={ROUTES.GSTR_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.GSTR_REPORT)}>
          <img src={gstr_icon} alt={'GSTR Report Link'} />
          <ListItemText primary="GSTR Report" />
          <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
        </ListItem>
        {fbv && (
          <ListItem className={styles.linkItem} component={Link} to={ROUTES.FBV_STORAGE_CHARGES_REPORT} onClick={() => triggerReportViewedEvent(ROUTES.FBV_STORAGE_CHARGES_REPORT)}>
            <img src={storage_icon} alt={'FBV Storage Charges Link'} />
            <ListItemText primary="FBV Storage Charges" />
            <ArrowForwardOutlinedIcon fontSize="small" className={styles.rightArrow}/>
          </ListItem>
        )}
      </List>
    </Box>
  );
}

export default ReportList;