import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from "./TotalStockSalesReport.module.scss";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { TotalGrnDataHash, TotalStockSalesReportDataHash } from "slices/totalStockSalesReportSlice";
import { formatCurrency } from "helpers/formatUtils";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import { useState } from "react";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import { Box } from "@mui/material";


export const fixedColumn:FixedColumnPropType<TotalStockSalesReportDataHash> = {
  label: 'Product / SKU',
  accessor: 'sku',
  width: 120,
  maxWidth: 120,
  thresold: (200 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.sku || ''}
    src={row.product_image || ''}
    fbv_enabled={row.fbv_enabled}
  />
}

const GrnDataListTooltipMobile:React.FC<{data: TotalGrnDataHash[], value: number}> = ({data, value}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <ClickTooltip
      show={showTooltip}
      showPopper={setShowTooltip}
      title={<>
        {data.map((item) => {
          return <Box className={styles.grnListItem}>
            <p className={styles.receivedAt}>{item.received_at}</p>
            <p className={styles.quantity}>{item.quantity}</p>
          </Box>
        })}
      </>} 
      className={styles.tooltipStyle}
    >
      <div onClick={() => setShowTooltip(true)}>{value}</div>
    </ClickTooltip>
  );
}

export const totalStockSalesReportColumns = (isMobile: boolean):ColumnPropType<TotalStockSalesReportDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'sku',
    minWidth: 200,
    cell: ({row}) => <ProductThumbnail 
      sku={row.sku || ''}
      title={row.title}
      src={row.product_image || ''}
      fbv_enabled={row.fbv_enabled}
    />
  },
  {
    label: 'Category',
    accessor: 'category',
    minWidth: 150,
    align: 'center',
  },
  {
    label: 'GRN Quantitiy',
    accessor: 'total_received_quantity',
    minWidth: 150,
    sortable: true,
    align: 'center',
    cell: ({value, row}) => {
      if(value === 0) return value;
      return isMobile ? (
        <GrnDataListTooltipMobile data={row.total_grn_data} value={value} key={row.unique_key} />
      ): (
        <LightTooltip
          title={
            <>
              {row.total_grn_data.map((item) => {
                return <Box className={styles.grnListItem}>
                  <p className={styles.receivedAt}>{item.received_at}</p>
                  <p className={styles.quantity}>{item.quantity}</p>
                </Box>
              })}
            </>
          }
          placement="bottom"
          className={styles.tooltipStyle}
        >
          {value}
        </LightTooltip>
      )
    }
  },
  {
    label: 'Sold Quantitiy',
    accessor: 'sold_quantity',
    minWidth: 150,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Return/RTO Quantitiy',
    accessor: 'return_rto_quantity',
    minWidth: 200,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Current Stock(SOH)',
    accessor: 'current_stock_quantity',
    minWidth: 200,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Sales Amount',
    accessor: 'sales_amount',
    minWidth: 150,
    cell: ({value}) => formatCurrency(value),
    align: 'center',
  },
];