import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, Link, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './TotalStockSalesReport.module.scss';
import Loader from "components/atoms/Loader/Loader";
import { setTotalStockSalesReportCategoryFilter, setTotalStockSalesReportProductIdFilter} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, totalStockSalesReportColumns } from "./TotalStockSalesReport.constant";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import {setSort, TotalStockSalesReportDataHash, totalStockSalesReportApi, totalStockSalesReportDownloadReportApi } from "slices/totalStockSalesReportSlice";

const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
]

const TotalStockSalesReport = () => {

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const dispatch = useAppDispatch();
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const totalStockSalesReportProductIdFilter = useAppSelector((state: RootState) => state.filters.totalStockSalesReportProductIdFilter);
  const totalStockSalesReportCategoryFilter = useAppSelector((state: RootState) => state.filters.totalStockSalesReportCategoryFilter);

  const totalStockSalesReportData = useAppSelector((state) => state.totalStockSalesReport);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(totalStockSalesReportApi({
        totalStockSalesReportProductIdFilter,
        totalStockSalesReportCategoryFilter,
        headers,
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      localStorage.removeItem("userInfo");
      dispatch(resetAuth());
    }
  };

  

  const handleSort = (column: keyof TotalStockSalesReportDataHash) => {
    const direction = column === totalStockSalesReportData.sortedColumn && totalStockSalesReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {page_link: window.location.href, }
    );
  }, []);

  useEffect(() => {
    if (totalStockSalesReportCategoryFilter !== ''){
      const av_categories = totalStockSalesReportData.totalStockSalesReportData?.available_categories || [];
      if (!av_categories.includes(totalStockSalesReportCategoryFilter)){
        setTotalStockSalesReportCategoryFilter('');
      }
    }
  }, [totalStockSalesReportData.totalStockSalesReportData?.available_categories]);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(totalStockSalesReportDownloadReportApi({
          totalStockSalesReportProductIdFilter,
          totalStockSalesReportCategoryFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {report_type: 'stock_sales_report'}
          );
        }
      }else{
        localStorage.removeItem("userInfo");
        dispatch(resetAuth());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  let availableCategories = totalStockSalesReportData.totalStockSalesReportData?.available_categories.map(val => ({label: val, value: val})) || [];
  availableCategories = [...availableCategories, {label: 'All', value: ''}];
  availableCategories.reverse();

  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!totalStockSalesReportProductIdFilter ? [{id: 'productId', label: 'Product ID', value: totalStockSalesReportProductIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!totalStockSalesReportCategoryFilter ? [{id: 'category', label: 'Category', value: totalStockSalesReportCategoryFilter, type: 'select', options: availableCategories}] : []) as AppliedFilterType[],
  ];

  useEffect(() => {
    if(fbv && filterApplied){
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied, fbv]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setTotalStockSalesReportProductIdFilter(value as string)); break;
      }
      case 'category': {
        dispatch(setTotalStockSalesReportCategoryFilter(value as string)); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setTotalStockSalesReportProductIdFilter(''));
    dispatch(setTotalStockSalesReportCategoryFilter(''));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'category' : {
          dispatch(setTotalStockSalesReportCategoryFilter(value as string)); break;
        }
      }
    }
    setFilterApplied(true);
  }

  if(!fbv){
    return (
      <Box className={styles.reportWrapper}>
        <Box className={styles.messageBox}>
          <p className={styles.message}>{"You have not opted for Fulfilled by Vaaree and hence you won't be able to access this report."}</p>
          <p className={styles.mailLabel}>{"For any help, you can email:"}</p>
          <Link className={styles.mailLink} href="mailto:procurement@vaaree.com">procurement@vaaree.com</Link>
        </Box>
      </Box>
    );
  }


  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Stock and Sales Report">
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <SelectFilter
                label={'Category'} 
                value={totalStockSalesReportCategoryFilter}
                options={availableCategories}
                onChange={(value) => {
                  dispatch(setTotalStockSalesReportCategoryFilter(value));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          selectFilters={[
            {
              id: 'category',
              label: 'Category',
              type: 'select',
              value: totalStockSalesReportCategoryFilter,
              options: availableCategories,
            },
          ]}
        />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<TotalStockSalesReportDataHash>
          data={totalStockSalesReportData?.totalStockSalesReportData?.total_stock_sales_data || []}
          columns={totalStockSalesReportColumns(isMobile)}
          sortedColumn={totalStockSalesReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={totalStockSalesReportData.sortDirection}
          showPagination
          totalCount={totalStockSalesReportData?.totalStockSalesReportData?.total_stock_sales_data?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}
    </Box>
  );

};
  
export default TotalStockSalesReport;
